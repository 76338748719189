import AvailabilityService from "../services/AvailabilityService.js";
import DateTimeService from "../services/DateTimeService";
import Cmd from "../utils/Cmd";
import loop from "../utils/loop";

const {isAllDay, isHours, isInRange} = AvailabilityService;
const {assign, keys} = Object;

const localizePath = (path) => {
  if (I18n.locale == I18n.defaultLocale) {
    return `/${path}`;
  } else {
    return `/${I18n.locale}/${path}`;
  }
};

const apiPath = (slug) => "/api/v1" + localizePath(`restaurants/${slug}/hours`);

const emptyAvailability = () => ({
  isEnabled: false,
  isOnlinePossible: false,
  isHoursModuleLoaded: false,
  onlineHoursType: "",
  onlineHoursString: "",
  info: null,
});

const init = (state = {}) => {
  const initialState = assign(
    {
      restaurants: [],
      restaurantsAvailability: [],
      slug: "",
      ordering: emptyAvailability(),
    },
    state
  );
  return [
    initialState,
    Cmd.of((dispatch, getState) => {
      loop(() => dispatch({type: "UPDATE_HOURS"}), 60);
    }),
  ];
};

const update = (state, action) => {
  switch (action.type) {
    case "GET_RESTAURANT_HOURS":
      return [
        assign({}, state),
        Cmd.of((dispatch, getState) => {
          $(document).on("loaded.skubacz.cart", (e) => {
            const restaurant = Skubacz.CartHooks.restaurant();
            dispatch({
              type: "STORE_RESTAURANT_DATA",
              payload: {
                slug: restaurant.slug,
                isEnabled:
                  restaurant.enabled && restaurant.online_ordering_enabled,
              },
            });
            dispatch({type: "UPDATE_HOURS"});
          });
          const slug = action.payload.slug;
          $.get(apiPath(slug), {}, function (result) {
            const payload = {
              slug: slug,
              openingHours: result.restaurant_hours,
              onlineHours: result.online_hours,
              phoneHours: result.phone_hours,
              holidays: result.holidays,
              upcomingHolidays: result.upcoming_holidays,
              isHoursModuleLoaded: true,
            };
            dispatch({type: "STORE_HOURS", payload});
            dispatch({type: "UPDATE_HOURS"});
          });
        }),
      ];
    case "STORE_RESTAURANT_DATA":
      return [
        assign({}, state, {
          slug: action.payload.slug,
          isEnabled: action.payload.isEnabled,
        }),
        Cmd.none,
      ];
    case "STORE_HOURS":
      const restaurants = (state.restaurants || []).filter(
        (r) => r.slug != action.payload.slug
      );
      restaurants.push(action.payload);
      return [assign({}, state, {restaurants}), Cmd.none];
    case "UPDATE_HOURS":
      const dateTime = DateTimeService.getDateTime(
        Skubacz.configuration.time_zone
      );
      const dateTimeString = DateTimeService.timeToString(
        dateTime,
        "YYYY-MM-DD HH:mm:ss"
      );
      const restaurantsAvailability = (state.restaurants || []).map((r) =>
        assign(
          {slug: r.slug},
          {isHoursModuleLoaded: r.isHoursModuleLoaded},
          {upcomingHolidays: r.upcomingHolidays || []}, // || [] added here because of caching errors?
          getHolidaysData(r.holidays, dateTimeString),
          getOpeningHoursData(r.openingHours, r.holidays, dateTimeString),
          getOnlineHoursData(r.onlineHours, r.holidays, dateTimeString),
          getPhoneHoursData(r.phoneHours, r.holidays, dateTimeString)
        )
      );
      const av =
        restaurantsAvailability.find((x) => x.slug == state.slug) ||
        emptyAvailability();
      const ordering = {
        isEnabled: state.isEnabled || false,
        isOnlinePossible: av.isOnlinePossible,
        onlineHoursType: av.onlineHoursType,
        onlineHoursString: av.onlineHoursString,
        isHoursModuleLoaded: av.isHoursModuleLoaded,
        info: av.info,
      };
      return [
        assign({}, state, {restaurantsAvailability, ordering}),
        Cmd.of((dispatch, getState) => {}),
      ];
    default:
      return [state, Cmd.none];
  }
};

const prepairDateTimeData = (dateTimeString) => {
  const dateTime = DateTimeService.parseDateTime(
    dateTimeString,
    "YYYY-MM-DD HH:mm:ss"
  );
  const timeString = DateTimeService.timeToString(dateTime, "HH:mm:ss");
  const timeInSeconds = DateTimeService.timeToSeconds(timeString);
  const oneDayInSeconds = DateTimeService.daysToSeconds(1);
  const yesterdayDateTime = DateTimeService.shiftDays(dateTime, -1);
  const todayDayIndex = DateTimeService.dayIndex(dateTime);
  const todayDayIndexIso = DateTimeService.dayIndexIso(dateTime);
  const yesterdayDayIndex = DateTimeService.dayIndex(yesterdayDateTime);
  const yesterdayDayIndexIso = DateTimeService.dayIndexIso(yesterdayDateTime);
  return {
    dateTime,
    yesterdayDateTime,
    oneDayInSeconds,
    timeInSeconds,
    todayDayIndex,
    todayDayIndexIso,
    yesterdayDayIndex,
    yesterdayDayIndexIso,
  };
};

const filterHolidays = (holidays, dateTimeString) => {
  const {dateTime, yesterdayDateTime} = prepairDateTimeData(dateTimeString);
  const todayHolidays = holidays.find((h) =>
    DateTimeService.isDayBetween(
      dateTime,
      DateTimeService.parseDateTime(h.start_date, "YYYY-MM-DD"),
      DateTimeService.parseDateTime(h.end_date, "YYYY-MM-DD")
    )
  );
  const yesterdayHolidays = holidays.find((h) =>
    DateTimeService.isDayBetween(
      yesterdayDateTime,
      DateTimeService.parseDateTime(h.start_date, "YYYY-MM-DD"),
      DateTimeService.parseDateTime(h.end_date, "YYYY-MM-DD")
    )
  );
  return {
    todayHolidays,
    yesterdayHolidays,
    hasTodayHolidays: todayHolidays !== undefined,
    hasYesterdayHolidays: yesterdayHolidays !== undefined,
  };
};

const getHolidaysData = (holidays, dateTimeString) => {
  const {
    todayHolidays,
    hasTodayHolidays,
    yesterdayHolidays,
    hasYesterdayHolidays,
  } = filterHolidays(holidays, dateTimeString);
  return {
    holidaysHoursString: hasTodayHolidays
      ? todayHolidays.hours
      : hasYesterdayHolidays
        ? yesterdayHolidays.hours
        : "",
    holidaysInfo: hasTodayHolidays
      ? todayHolidays.info
      : hasYesterdayHolidays
        ? yesterdayHolidays.info
        : "",
  };
};

const getHoursData = (hours, holidays, dateTimeString) => {
  const {
    oneDayInSeconds,
    timeInSeconds,
    todayDayIndex,
    todayDayIndexIso,
    yesterdayDayIndex,
    yesterdayDayIndexIso,
  } = prepairDateTimeData(dateTimeString);
  const {
    todayHolidays,
    hasTodayHolidays,
    yesterdayHolidays,
    hasYesterdayHolidays,
  } = filterHolidays(holidays, dateTimeString);
  const todayHours = hasTodayHolidays
    ? todayHolidays
    : hours[todayDayIndex] || {};
  const isActiveToday = isInRange(
    todayHours.time_range.start,
    todayHours.time_range.stop,
    timeInSeconds
  );
  const yesterdayHours = hasYesterdayHolidays
    ? yesterdayHolidays
    : hours[yesterdayDayIndex] || {};
  const isActiveYesterday = isInRange(
    0,
    yesterdayHours.time_range.stop,
    timeInSeconds + oneDayInSeconds
  );
  const currHours =
    isActiveToday || !isActiveYesterday ? todayHours : yesterdayHours;
  return {
    isActive: isActiveToday || isActiveYesterday,
    dayIndex:
      isActiveToday || !isActiveYesterday
        ? todayDayIndexIso
        : yesterdayDayIndexIso,
    hoursType: currHours.type,
    hoursString: currHours.hours,
    info: currHours.info,
  };
};

const getOpeningHoursData = (hours, holidays, dateTimeString) => {
  const {isActive, dayIndex, hoursType, hoursString} = getHoursData(
    hours,
    holidays,
    dateTimeString
  );
  return {
    isRestaurantOpen: isActive,
    openingDayIndex: dayIndex,
    openingHoursType: hoursType,
    openingHoursString: hoursString,
  };
};

const getOnlineHoursData = (hours, holidays, dateTimeString) => {
  const {isActive, dayIndex, hoursType, hoursString, info} = getHoursData(
    hours,
    holidays,
    dateTimeString
  );
  return {
    isOnlinePossible: isActive,
    onlineDayIndex: dayIndex,
    onlineHoursType: hoursType,
    onlineHoursString: hoursString,
    info: info,
  };
};

const getPhoneHoursData = (hours, holidays, dateTimeString) => {
  const {isActive, dayIndex, hoursType, hoursString} = getHoursData(
    hours,
    holidays,
    dateTimeString
  );
  return {
    isPhonePossible: isActive,
    phoneDayIndex: dayIndex,
    phoneHoursType: hoursType,
    phoneHoursString: hoursString,
  };
};

export default {
  init,
  update,
  prepairDateTimeData,
  filterHolidays,
  getHolidaysData,
  getOpeningHoursData,
  getOnlineHoursData,
  getPhoneHoursData,
};
