import GroupToggleNav from "./GroupToggleNav";
import GroupToggleContent from "./GroupToggleContent";
import onInit from "../utils/onInit";
import isValidSelector from "../utils/isValidSelector";
import Scroll from "../utils/Scroll";
import forwardTo from "../utils/forwardTo";
import Cmd from "../utils/Cmd";

const hashUrlFormat = "#";
const toggleGroupLinkSelector = ".js-toggle-group-link";

// Define a prefix for ID attribute to prevent default browser jump at page load.
const anchorPrefix = "section-";
const menuSectionAnchor = `${anchorPrefix}menu`;
const isOnePageMode = () => $(`#${menuSectionAnchor}`).length;

/**
 * Update ID attribute of GroupToggle items to prevent default jump of the browser at page load.
 *
 * This function has to be called before GroupToggle init.
 */
function updateIdAttr() {
  $(".js-group-item").each((index, el) => {
    const $el = $(el);
    const item = $el.attr("id");

    if (!isCorrectAnchorFormat(item)) {
      $el.attr("id", getAnchorFromItem(item));
    }
  });
}

/**
 * Go to menu section in case of one page layout when menu group hash is present in the URL.
 *
 * Note that "list-all" mode is a special case and it's handled by `scrollToGroup()`, therefore it doesn't require separate initialization.
 */
function initMenuSectionStartup(state) {
  if (
    state.mode !== "list-all" &&
    location.hash.indexOf("#menu-") !== -1 &&
    isOnePageMode()
  ) {
    Scroll.toElement($(`#${menuSectionAnchor}`)[0], 400);
  }
}

/**
 * Check ID attribute for correct anchor.
 *
 * @param {string} anchor
 * @returns {boolean}
 */
const isCorrectAnchorFormat = (anchor = "") =>
  anchor.substring(0, anchorPrefix.length) === anchorPrefix;

/**
 * Get GroupToggle item form hash.
 *
 * @param {string} hash
 * @returns {string} Original ID attribute without anchor prefix (GroupToggle item).
 */
const getItemFromHash = (hash = location.hash) =>
  getItemFromAnchor(hash.substring(hashUrlFormat.length));

/**
 * Get full ID attribute of GroupToggle item with anchor prefix.
 *
 * @param {string} item
 * @returns {string} ID attribute with anchor prefix.
 */
const getAnchorFromItem = (item = "") =>
  isCorrectAnchorFormat(item) ? item : anchorPrefix + item;

/**
 * Get original item name from ID attribute of GroupToggle item.
 *
 * @param {string} anchor ID attribute with anchor.
 * @returns {string} Original ID attribute without anchor prefix (GroupToggle item).
 */
const getItemFromAnchor = (anchor = "") =>
  isCorrectAnchorFormat(anchor)
    ? anchor.substring(anchorPrefix.length)
    : anchor;

/**
 * Get original item name from ScrollSpy target selector, e.g. `#anchor-item` to `item`.
 *
 * @param scrollSpyTarget
 */
const getItemFromScrollSpyTarget = (scrollSpyTarget = "") =>
  scrollSpyTarget.substring(anchorPrefix.length + 1); // 1 is hash #

/**
 * Get jQuery object with correct anchor.
 *
 * @param {string} item GroupToggle item
 * @returns {object} jQuery object.
 */
const get$FromItem = (item = "") => $("#" + getAnchorFromItem(item));

/**
 * Check if item isn't set.
 *
 * @param currentItem
 * @param previousItem
 * @returns {boolean}
 */
const isItemNotSet = ({ currentItem, previousItem }) =>
  currentItem === "" && previousItem !== "";

/**
 * Toggle item.
 *
 * @param newItem
 * @param currentItem
 * @returns {string}
 */
const toggleItem = (newItem, currentItem) =>
  newItem === currentItem ? "" : newItem;

/**
 * Check if element with specific id is an item of GroupToggle.
 *
 * @param {string} elementId
 * @returns {boolean}
 */
function isGroupToggleItem(elementId) {
  const selector = "#" + getAnchorFromItem(elementId);
  return isValidSelector(selector) && $(selector).hasClass("js-group-item");
}

/**
 * Initiate state of GroupToggle.
 *
 * @param state
 * @returns {function(*, *)}
 */
function initGroupToggleState(state) {
  return (dispatch, getState) => {
    const $group = $(".js-group");
    const mode = Skubacz.Device.screen.isMobileSize()
      ? $group.attr("data-mode-mobile") || "accordion"
      : state.mode || $group.attr("data-mode") || "tab";
    let currentItem = state.currentItem;

    if (isGroupToggleItem(getItemFromHash())) {
      currentItem = getItemFromHash();
    } else if (currentItem === "" || !isGroupToggleItem(currentItem)) {
      const accordionInitState = $group.attr("data-accordion-init");
      if (mode === "accordion" && accordionInitState === "close") {
        currentItem = "";
      } else {
        currentItem =
          getItemFromAnchor(
            $group.find(".js-group-item.is-active").eq(0).attr("id")
          ) ||
          getItemFromAnchor($group.find(".js-group-item").eq(0).attr("id"));
      }
    }

    prepareGroupTarget(toggleGroupLinkSelector);
    dispatch({ type: "CHANGE_MODE", mode: mode, effectType: "init" });
    dispatch({ type: "CHANGE_ITEM", target: currentItem, effectType: "init" });
  };
}

/**
 * Scroll to target group.
 *
 * @param mode
 * @param currentItem
 */
function scrollToGroup(mode, currentItem) {
  // Hash check ensures we won't override native anchor which is not part of the menu.
  if (
    location.hash &&
    isGroupToggleItem(getItemFromHash(location.hash)) &&
    isGroupToggleItem(currentItem)
  ) {
    if (mode === "list-all") {
      Scroll.toElement(get$FromItem(currentItem)[0], 400);
    } else {
      Scroll.scrollIfAbove(get$FromItem(currentItem)[0], 400);
    }
  }
}

/**
 * Update browser history with selected item.
 *
 * @param currentItem
 */
function updateHistory(currentItem) {
  if (
    history.pushState &&
    currentItem !== getItemFromHash() &&
    currentItem !== ""
  ) {
    history.pushState(null, null, hashUrlFormat + currentItem);
  }
}

/**
 * Trigger GroupToggle events.
 *
 * @param currentItem
 * @param previousItem
 * @param mode
 */
function triggerEvents(currentItem, previousItem, mode) {
  const $currentItem = get$FromItem(currentItem);
  const $previousItem = get$FromItem(previousItem);
  const hiddenEvent = $.Event("hidden.skubacz.group-toggle", {
    relatedTarget: $currentItem[0],
  });
  const shownEvent = $.Event("shown.skubacz.group-toggle", {
    relatedTarget: $previousItem[0],
  });

  if ($currentItem.length) {
    $currentItem.trigger(shownEvent, [mode]);

    if ($previousItem.length) {
      $previousItem.trigger(hiddenEvent, [mode]);
    }
  } else if ($previousItem.length) {
    $previousItem.trigger(hiddenEvent, [mode]);
  }
}

/**
 * Apply item side effects.
 *
 * @param state
 * @param effectType
 * @returns {function(*, *)}
 */
function applyItemSideEffects(state, effectType = "default") {
  return (dispatch, getState) => {
    if (effectType !== "init") {
      updateHistory(state.currentItem);
    }

    scrollToGroup(state.mode, state.currentItem, state.previousItem);
    triggerEvents(state.currentItem, state.previousItem, state.mode);
  };
}

/**
 * Check currentItem and update it if necessary.
 * @param state
 * @param target
 * @returns {function(*, *)}
 */
function changeItem(state, target) {
  return (dispatch, getState) => {
    if (state.currentItem !== target) {
      dispatch({ type: "CHANGE_ITEM", target: target });
    }
  };
}

/**
 * Update state of the application.
 *
 * @param state
 * @param action
 * @returns {*[]} An array with new state and Cmd object.
 */
function updateNormal(state, action) {
  switch (action.type) {
    case "CHANGE_MODE": {
      const newState = Object.assign({}, state, { mode: action.mode });
      const cmd =
        newState.currentItem === "" &&
        newState.mode !== "accordion" &&
        action.effectType !== "init"
          ? Cmd.of(initGroupToggleState(newState))
          : Cmd.none;

      return [newState, cmd];
    }
    case "CHANGE_ITEM": {
      const [groupToggleNavState, groupToggleNavCmd] = GroupToggleNav.update(
        state.currentItem,
        action
      );
      const [groupToggleContentState, groupToggleContentCmd] =
        GroupToggleContent.update(state.currentItem, action);
      const newState = Object.assign({}, state, {
        previousItem: state.currentItem,
        currentItem: groupToggleNavState || groupToggleContentState,
      });

      return [
        newState,
        Cmd.batch(
          Cmd.of(applyItemSideEffects(newState, action.effectType)),
          groupToggleNavCmd.tag("GROUP_TOGGLE_NAV_ACTION"),
          groupToggleContentCmd.tag("GROUP_TOGGLE_CONTENT_ACTION")
        ),
      ];
    }
    case "SCROLL_SPY_ACTION": {
      const [scrollSpyState, scrollSpyCmd] = ScrollSpy.update(
        state.scrollSpy,
        action.payload
      );
      let stateDiff = {};
      let cmd = Cmd.none;

      if (
        action.payload.type === "ACTIVATE_ITEM" &&
        scrollSpyState.activeTarget
      ) {
        stateDiff = {
          previousItem: state.currentItem,
          currentItem: getItemFromScrollSpyTarget(scrollSpyState.activeTarget),
        };
        cmd = Cmd.of(updateScrollSpyOffset(state));
      }

      return [
        Object.assign(
          {},
          state,
          {
            scrollSpy: scrollSpyState,
          },
          stateDiff
        ),
        Cmd.batch(cmd, scrollSpyCmd.tag("SCROLL_SPY_ACTION")),
      ];
    }
    case "GROUP_TOOGLE_NAV_ACTION": {
      return updateGroupToggleNav(state, action);
    }
    case "GROUP_TOOGLE_CONTENT_ACTION": {
      return updateGroupToggleContent(state, action);
    }
    default:
      return [state, Cmd.none];
  }
}

/**
 * Update state of GroupToggleNav component.
 *
 * @param state
 * @param action
 * @returns {[*,*]}
 */
function updateGroupToggleNav(state, action) {
  const [groupToggleNavState, groupToggleNavCmd] = GroupToggleNav.update(
    state.currentItem,
    action.payload
  );

  return [
    Object.assign({}, state, { currentItem: groupToggleNavState }),
    Cmd.batch(
      Cmd.of(changeItem(state, groupToggleNavState)),
      groupToggleNavCmd.tag("GROUP_TOOGLE_NAV_ACTION")
    ),
  ];
}

/**
 * Update state of GroupToggleContent component.
 *
 * @param state
 * @param action
 * @returns {[*,*]}
 */
function updateGroupToggleContent(state, action) {
  const [groupToggleContentState, groupToggleContentCmd] =
    GroupToggleContent.update(state.currentItem, action.payload);

  return [
    Object.assign({}, state, { currentItem: groupToggleContentState }),
    Cmd.batch(
      Cmd.of(changeItem(state, groupToggleContentState)),
      groupToggleContentCmd.tag("GROUP_TOOGLE_CONTENT_ACTION")
    ),
  ];
}

/**
 * Update state of the application in case of accordion mode.
 *
 * @param state
 * @param action
 * @returns {*[]} An array with new state and Cmd object.
 */
function updateAccordion(state, action) {
  switch (action.type) {
    case "CHANGE_MODE": {
      if (isItemNotSet(state)) {
        const newState = Object.assign({}, state, {
          mode: action.mode,
          currentItem: state.previousItem,
        });

        return [
          newState,
          Cmd.of(applyItemSideEffects(newState, action.effectType)),
        ];
      } else {
        return updateNormal(state, action);
      }
    }
    case "CHANGE_ITEM":
      return updateNormal(
        state,
        Object.assign({}, action, {
          target: toggleItem(action.target, state.currentItem),
        })
      );
    case "GROUP_TOOGLE_NAV_ACTION": {
      return updateNormal(
        state,
        Object.assign({}, action.payload, {
          target: toggleItem(action.payload.target, state.currentItem),
        })
      );
    }
    case "GROUP_TOOGLE_CONTENT_ACTION": {
      return updateNormal(
        state,
        Object.assign({}, action.payload, {
          target: toggleItem(action.payload.target, state.currentItem),
        })
      );
    }
    default:
      return [state, Cmd.none];
  }
}

/**
 * Prepare GroupToggle for external components.
 *
 * Add `data-target` attribute with correct anchor.
 *
 * @param selector
 */
function prepareGroupTarget(selector) {
  $(selector).each((index, el) => {
    const $el = $(el);
    $el.attr(
      "data-target",
      "#" + getAnchorFromItem($el.attr("href").substring(1))
    );
  });
}

export default {
  /**
   * Group utils for external use.
   */
  utils: {
    updateIdAttr,
    getItemFromHash,
    getItemFromAnchor,
    isGroupToggleItem,
  },

  /**
   * Initiate GroupToggle state.
   *
   * @param {object} state Object containing initial state.
   * @param {string} state.mode Mode of the GroupToggle component. It can be `tab`, `accordion` or `list-all`.
   * @param {array} state.previousItem Previous item.
   * @param {string} state.currentItem Currently selected item.
   */
  init: (state = {}) => {
    const [groupToggleNavState, groupToggleNavCmd] = GroupToggleNav.init();
    const [groupToggleContentState, groupToggleContentCmd] =
      GroupToggleContent.init();

    state = Object.assign(
      {
        mode: "",
        previousItem: "",
        currentItem: groupToggleNavState || groupToggleContentState,
      },
      state
    );

    return [
      state,
      Cmd.batch(
        Cmd.of(initGroupToggleState(state)),
        groupToggleNavCmd.tag("GROUP_TOGGLE_NAV_ACTION"),
        groupToggleContentCmd.tag("GROUP_TOGGLE_CONTENT_ACTION")
      ),
    ];
  },

  /**
   * Update state of the component.
   *
   * @param state
   * @param action
   * @returns {*[]}
   */
  update: (state, action) => {
    if (state.mode === "accordion") {
      return updateAccordion(state, action);
    } else {
      return updateNormal(state, action);
    }
  },

  /**
   * Update the view.
   *
   * @param $element
   * @param state
   * @param dispatch
   */
  view: ($element, state, dispatch) => {
    GroupToggleNav.view(
      $(".js-toggle-group-link"),
      state.currentItem,
      forwardTo(dispatch, "GROUP_TOOGLE_NAV_ACTION")
    );
    GroupToggleContent.view(
      $element.find(".js-group-item"),
      state.currentItem,
      forwardTo(dispatch, "GROUP_TOOGLE_CONTENT_ACTION")
    );

    onInit($element, () => {
      window
        .matchMedia(
          "(min-width: " +
            Skubacz.Device.breakpoints.gridFloatBreakpoint +
            "px)"
        )
        .addListener((mql) => {
          if (mql.matches) {
            dispatch({
              type: "CHANGE_MODE",
              mode: $element.attr("data-mode") || "tab",
            });
          } else {
            dispatch({
              type: "CHANGE_MODE",
              mode: $element.attr("data-mode-mobile") || "accordion",
            });
          }
        });

      $(window).on("hashchange", () => {
        const item = getItemFromHash();

        if (isGroupToggleItem(item)) {
          dispatch({ type: "CHANGE_ITEM", target: item });
        }
      });

      initMenuSectionStartup(state);
    });
  },
};
