import Cmd from "../utils/Cmd";

const dispatchEff = (disp) => (action) => {
  return () => {
    return disp(action);
  };
};

const debug = (action) => {
  console.log("Debug:" + action);
  return action;
};

const convertResult = (result) => {
  return [
    result.value0,
    Cmd.of((dsp, getState) => {
      const v1 = result.value1(dispatchEff(dsp));
      return v1();
    }),
  ];
};

/* Parse needs to return an exception if it fails, represented as an Eff in
 * purescript
 */
const idParse = (jsState) => () => jsState;
/*
 * Wrap init, update and view of PursComponent so they can be used from normal JS components.
 * Other exports from PursComponent are just copied as is, so all functions will be curried.
 */
export default function (PursComponent) {
  const init = (state) => {
    const parse = PursComponent.parse || idParse;
    const result = PursComponent.init(parse(state)());
    return convertResult(result);
  };

  const update = (state, action) => {
    const result = PursComponent.update(state)(action);
    return convertResult(result);
  };

  const view = ($element, state, dispatch) => {
    PursComponent.view($element)(state)(dispatchEff(dispatch))();
  };

  return Object.assign({}, PursComponent, {
    init,
    update,
    view,
  });
}
