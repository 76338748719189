/**
 * Keep dropdown open after click on menu item.
 *
 * @param selector
 */
export default function (selector) {
  $(selector)
    .on("shown.bs.dropdown", function (e) {
      $(e.currentTarget)
        .find(".dropdown-menu a")
        .on("click", function (e) {
          $(e.currentTarget)
            .closest(".dropdown")
            .data("keep-dropdown-open", true);
        });
    })
    .on("hide.bs.dropdown", function (e) {
      const keepMenuOpen = $(e.currentTarget).data("keep-dropdown-open");

      $(e.currentTarget).removeData("keep-dropdown-open");

      return keepMenuOpen !== true;
    });
}
