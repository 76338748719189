import AvailabilityService from "../services/AvailabilityService.js";
import DateTimeService from "../services/DateTimeService";
import Cmd from "../utils/Cmd";
import loop from "../utils/loop";
import onInit from "../utils/onInit";

const { isAllDay, isHours, isInRange } = AvailabilityService;
const { assign, keys } = Object;

const init = (state = {}) => {
  const initialState = assign(
    {
      dishTypes: [],
      availableDishTypes: [],
      unavailableDishTypes: [],
      unavailableDishes: [],
    },
    state
  );
  return [
    initialState,
    Cmd.of((dispatch, getState) => {
      $(document).on("loaded.skubacz.cart", (e) => {
        const restaurant = Skubacz.CartHooks.restaurant();
        dispatch({
          type: "STORE_HOURS",
          payload: { dishTypes: restaurant.dish_types },
        });
        dispatch({ type: "UPDATE_HOURS" });
      });
      loop(() => dispatch({ type: "UPDATE_HOURS" }), 60);
    }),
  ];
};

const update = (state, action) => {
  switch (action.type) {
    case "STORE_HOURS":
      const dishTypes = action.payload.dishTypes;
      return [assign({}, state, { dishTypes }), Cmd.none];
    case "UPDATE_HOURS":
      const dateTime = DateTimeService.getDateTime(
        Skubacz.configuration.time_zone
      );
      const dateTimeString = DateTimeService.timeToString(
        dateTime,
        "YYYY-MM-DD HH:mm:ss"
      );
      const availableDishTypes = state.dishTypes.filter((dt) =>
        checkDishTypeAvailability(dt.availability || [], dateTimeString)
      );
      const unavailableDishTypes = state.dishTypes.filter(
        (dt) => availableDishTypes.indexOf(dt) === -1
      );
      const unavailableDishes = unavailableDishTypes.reduce(
        (arr, dt) => arr.concat(dt.dishes),
        []
      );
      return [
        assign({}, state, {
          availableDishTypes,
          unavailableDishTypes,
          unavailableDishes,
        }),
        Cmd.none,
      ];
    default:
      return [state, Cmd.none];
  }
};

const checkDishTypeAvailability = (avs, dateTimeString) => {
  const dateTime = DateTimeService.parseDateTime(
    dateTimeString,
    "YYYY-MM-DD HH:mm:ss"
  );
  const timeString = DateTimeService.timeToString(dateTime, "HH:mm:ss");
  const timeInSeconds = DateTimeService.timeToSeconds(timeString);
  const oneDayInSeconds = DateTimeService.daysToSeconds(1);
  const yesterdayDateTime = DateTimeService.shiftDays(dateTime, -1);
  const todayDayIndex = DateTimeService.dayIndex(dateTime);
  const yesterdayDayIndex = DateTimeService.dayIndex(yesterdayDateTime);
  const todayAv = avs[todayDayIndex];
  const yesterdayAv = avs[yesterdayDayIndex];
  const isAvailableToday =
    isAllDay(todayAv.type) ||
    (isHours(todayAv.type) &&
      isInRange(todayAv.start, todayAv.stop, timeInSeconds));
  const isAvailablePassing =
    isHours(yesterdayAv.type) &&
    isInRange(0, yesterdayAv.stop, timeInSeconds + oneDayInSeconds);
  return isAvailableToday || isAvailablePassing;
};

const view = ($element, state, dispatch) => {
  onInit($element, () => {
    $element.each((i, el) => makeElementAvailable($(el)));
  });

  const { availableDishTypes, unavailableDishTypes } = state;
  availableDishTypes.forEach((dt) => {
    const $el = $(
      $element
        .toArray()
        .find((e) => parseInt(e.getAttribute("data-id")) === dt.id)
    );
    if (!$el.hasClass("is-available")) {
      makeElementAvailable($el);
    }
  });

  unavailableDishTypes.forEach((dt) => {
    const $el = $(
      $element
        .toArray()
        .find((e) => parseInt(e.getAttribute("data-id")) === dt.id)
    );
    if ($el.hasClass("is-available")) {
      makeElementUnavailable($el);
    }
  });
};

/**
 * Set $elements (and $elements children) classes as available.
 *
 * @param {object} $el
 */
const makeElementAvailable = ($el) => {
  const target = $el.children("a").data("target");
  const $btns = $(`[data-target="${target}"]`);
  const $group = $(`${target}`);
  $el.addClass("is-available");
  $btns.removeClass("is-unavailable");
  $group.find(".js-availability").addClass("is-available");
  $group.find(".js-popover-advanced").popover("hide");
};

/**
 * Set $elements (and $elements children) classes as unavailable.
 *
 * @param {object} $el
 */
const makeElementUnavailable = ($el) => {
  const target = $el.children("a").data("target");
  const $btns = $(`[data-target="${target}"]`);
  const $group = $(`${target}`);
  $el.removeClass("is-available");
  $btns.addClass("is-unavailable");
  $group.find(".js-availability").removeClass("is-available");
  $group.find(".js-popover-advanced").popover("hide");
};

export default {
  init,
  update,
  view,
  checkDishTypeAvailability,
};
