const cartSelector = "#cart";
const cartFabSelector = ".js-cart-button.m-floating-action__btn";

/**
 * Initialize FAB animation.
 *
 * @param {jQuery} $cartFab The cart FAB element.
 */
function initFabAnimation($cartFab) {
  // Ongoing A/B test: `m-floating-action__btn--icon-only` vs. `m-floating-action__btn--text`.
  const animationClassName = $cartFab.hasClass(
    "m-floating-action__btn--icon-only"
  )
    ? "bounce"
    : "swing";

  $cartFab.addClass("animated");
  $cartFab.removeClass("fade");
  $cartFab.on("animationend", () => $cartFab.removeClass(animationClassName));

  // Modal is created dynamically in PS/JS, so handle it using event delegation.
  $(document).on("hidden.bs.modal", cartSelector, () =>
    $cartFab.addClass(animationClassName)
  );
}

/**
 * Show attention animation to indicate the cart button to the user.
 */
export default function () {
  const $cartFab = $(cartFabSelector);

  // Special animations only applies to themes with the cart button as floating action button.
  if ($cartFab.length >= 1) {
    initFabAnimation($cartFab);
  }
}
