/**
 * Display the appropriate number of stars according to the selected radio.
 *
 * @param {NodeList} radios All radios in a group.
 * @param {HTMLElement} selected Selected radio.
 */
function selectRate(radios, selected) {
  radios.forEach((radio) => {
    const label = radio.parentNode;

    if (radio.value <= selected.value) {
      label.classList.remove("icon-star-empty");
      label.classList.add("icon-star");
    } else {
      label.classList.remove("icon-star");
      label.classList.add("icon-star-empty");
    }
  });
}

/**
 * Stars rating for review component with native radio inputs.
 */
export function init() {
  const ratingGroups = document.querySelectorAll(".js-rating");

  ratingGroups.forEach((group) => {
    const radios = group.querySelectorAll('input[type="radio"]');

    radios.forEach((radio) =>
      radio.addEventListener("change", () => selectRate(radios, radio), false)
    );
  });
}
