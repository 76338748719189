import Cmd from "../utils/Cmd";
import id from "../utils/id";
import compose from "../utils/compose";

const toStateAndEffect = (result) => {
  if (Array.isArray(result) && result.length == 2 && result[1] instanceof Cmd) {
    return [result[0], result[1]];
  } else {
    return [result, Cmd.none];
  }
};

function create(initialState, update) {
  var currentState, effect;
  [currentState, effect] = toStateAndEffect(initialState);

  const listeners = [];

  const getState = () => currentState;

  const subscribe = (listener) => listeners.push(listener);

  const dispatch = function (action) {
    var result = update(currentState, action);
    [currentState, effect] = toStateAndEffect(result);
    // console.log(action)
    listeners.forEach((l) => {
      l();
    });

    effect.unsafePerform(dispatch, getState);
  };

  effect.unsafePerform(dispatch, getState);

  return {
    getState,
    subscribe,
    dispatch,
  };
}

/*
 * Create a function for running a top level component, given its jquery element
 * and initial state.
 * Will create a store, update the view on store changes and
 * perform the initial render. The store is returned, so additional
 * subscriptions may be added.
 */
function run({ init, view, update }) {
  return (element, initialState) => {
    const store = create(init(initialState), update);
    store.subscribe(() => {
      view(element, store.getState(), store.dispatch);
    });
    view(element, store.getState(), store.dispatch);
    return store;
  };
}

export default {
  create,
  run,
};
