import onInit from "../utils/onInit";
import GroupToggle from "../components/GroupToggle";
import Cmd from "../utils/Cmd";

function target($item) {
  return (
    GroupToggle.utils.getItemFromHash($item.attr("href")) ||
    $item.attr("data-target")
  );
}

export default {
  /**
   * Initiate GroupToggleNav state.
   *
   * @param {string} state Current item.
   */
  init: (state = "") => [state, Cmd.none],

  update: (state, action) => {
    switch (action.type) {
      case "CHANGE_ITEM":
        return [action.target, Cmd.none];
    }

    return [state, Cmd.none];
  },

  view: ($element, state, dispatch) => {
    $element.each((index, element) => {
      const $link = $(element);

      if (target($link) === state) {
        $link.addClass("is-active");
      } else {
        $link.removeClass("is-active");
      }
    });

    onInit($element, () => {
      $element.on("click", (e) => {
        const t = target($(e.currentTarget));

        if (GroupToggle.utils.isGroupToggleItem(t)) {
          dispatch({ type: "CHANGE_ITEM", target: t });
          e.preventDefault();
        }
      });
    });
  },
};
