import Cmd from "../utils/Cmd";
import GroupToggle from "../components/GroupToggle";

export default {
  init: (isActive = "") => [isActive, Cmd.none],

  update: (state, action) => {
    switch (action.type) {
      case "CHANGE_ITEM":
        return [action.target, Cmd.none];
    }

    return [state, Cmd.none];
  },

  view: ($element, state, dispatch) => {
    $element.each((index, element) => {
      const $el = $(element);
      const t = GroupToggle.utils.getItemFromAnchor($el.attr("id"));

      if (t === state) {
        $el.addClass("is-active");
      } else {
        $el.removeClass("is-active");
      }
    });
  },
};
