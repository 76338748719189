import GroupToggle from "./GroupToggle";
import Notify from "./Notify";
import Navigation from "./Navigation";
import Sticky from "./Sticky";

import DishTypesHours from "./DishTypesHours.js";
import * as RestaurantHoursPS from "../../../output/Components.RestaurantHoursPS/index.js";

import forwardTo from "../utils/forwardTo";
import Cmd from "../utils/Cmd";
import PsToJs from "./PsToJs";

const { assign } = Object;
const RestaurantHours = PsToJs(RestaurantHoursPS);

const addToCartState = (state) =>
  assign({}, state.restaurantHours.ordering, {
    unavailableDishes: state.dishTypesHours.unavailableDishes,
  });

/**
 * Initiate state for App component.
 *
 * @param {object} state Object containing initial state.
 * @param {object} state.groupToggle
 * @param {array} state.notify
 * @param {object} state.navigationState
 * @param {object} state.stickyState
 * @returns {*[]}
 */
function init(state = {}) {
  const [groupToggleState, groupToggleCmd] = GroupToggle.init(
    state.groupToggle || {}
  );
  const [notifyState, notifyCmd] = Notify.init(state.notify || []);
  const [navigationState, navigationCmd] = Navigation.init(
    state.navigationState || {}
  );
  const [stickyState, stickyCmd] = Sticky.init(state.stickyState || {});
  const [dishTypesHoursState, dishTypesHoursCmd] = DishTypesHours.init(
    state.dishTypesHours || {}
  );
  const [restaurantHoursState, restaurantHoursCmd] = RestaurantHours.init(
    state.restaurantHours || {}
  );

  return [
    assign({}, state, {
      groupToggle: groupToggleState,
      notify: notifyState,
      navigation: navigationState,
      sticky: stickyState,
      dishTypesHours: dishTypesHoursState,
      restaurantHours: restaurantHoursState,
    }),
    Cmd.batch(
      groupToggleCmd.tag("GROUP_TOGGLE_ACTION"),
      notifyCmd.tag("NOTIFY_ACTION"),
      navigationCmd.tag("NAVIGATION_ACTION"),
      stickyCmd.tag("STICKY_ACTION"),
      dishTypesHoursCmd.tag("DISH_TYPES_HOURS_ACTION"),
      restaurantHoursCmd.tag("RESTAURANT_HOURS_ACTION")
    ),
  ];
}

/**
 * Update component state using proper child update method.
 *
 * @param state
 * @param action
 * @returns {*[]}
 */
function update(state, action) {
  switch (action.type) {
    case "GROUP_TOGGLE_ACTION":
      const [groupToggleState, groupToggleCmd] = GroupToggle.update(
        state.groupToggle,
        action.payload
      );
      return [
        Object.assign({}, state, { groupToggle: groupToggleState }),
        groupToggleCmd.tag("GROUP_TOGGLE_ACTION"),
      ];
    case "NOTIFY_ACTION":
      const [notifyState, notifyCmd] = Notify.update(
        state.notify,
        action.payload
      );
      return [
        Object.assign({}, state, { notify: notifyState }),
        notifyCmd.tag("NOTIFY_ACTION"),
      ];
    case "NAVIGATION_ACTION":
      const [navigationState, navigationCmd] = Navigation.update(
        state.navigation,
        action.payload
      );
      return [
        Object.assign({}, state, { navigation: navigationState }),
        navigationCmd.tag("NAVIGATION_ACTION"),
      ];
    case "STICKY_ACTION":
      const [stickyState, stickyCmd] = Sticky.update(
        state.sticky,
        action.payload
      );
      return [
        Object.assign({}, state, { sticky: stickyState }),
        stickyCmd.tag("STICKY_ACTION"),
      ];

    case "DISH_TYPES_HOURS_ACTION":
      const [dishTypesHoursState, dishTypesHoursCmd] = DishTypesHours.update(
        state.dishTypesHours,
        action.payload
      );
      return [
        assign({}, state, { dishTypesHours: dishTypesHoursState }),
        dishTypesHoursCmd.tag("DISH_TYPES_HOURS_ACTION"),
      ];

    case "RESTAURANT_HOURS_ACTION":
      const [restaurantHoursState, restaurantHoursCmd] = RestaurantHours.update(
        state.restaurantHours,
        action.payload
      );
      return [
        assign({}, state, { restaurantHours: restaurantHoursState }),
        restaurantHoursCmd.tag("RESTAURANT_HOURS_ACTION"),
      ];

    default:
      return [state, Cmd.none];
  }
}

/**
 * Update the view.
 *
 * @param $element
 * @param state
 * @param dispatch
 */
function view($element, state, dispatch) {
  if ($(".js-group").length > 0) {
    GroupToggle.view(
      $(".js-group"),
      state.groupToggle,
      forwardTo(dispatch, "GROUP_TOGGLE_ACTION")
    );
  }
  if ($(".js-notify").length > 0) {
    Notify.view(
      $(".js-notify"),
      state.notify,
      forwardTo(dispatch, "NOTIFY_ACTION")
    );
  }
  if ($(".js-navigation").length > 0) {
    Navigation.view(
      $(".js-navigation"),
      state.navigation,
      forwardTo(dispatch, "NAVIGATION_ACTION")
    );
  }
  if ($(".js-stick-in-parent").length > 0) {
    Sticky.view(
      $(".js-stick-in-parent"),
      state.sticky,
      forwardTo(dispatch, "STICKY_ACTION")
    );
  }
  if ($(".js-dish-types-hours").length > 0) {
    DishTypesHours.view(
      $(".js-dish-types-hours"),
      state.dishTypesHours,
      forwardTo(dispatch, "DISH_TYPES_HOURS_ACTION")
    );
  }
  if ($(".js-restaurant-hours").length > 0) {
    RestaurantHours.view(
      $(".js-restaurant-hours"),
      state.restaurantHours,
      forwardTo(dispatch, "RESTAURANT_HOURS_ACTION")
    );
  }
}

export default {
  init,
  update,
  view,
};
