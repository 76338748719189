// _translate :: forall a. Fn2 String a String
export function _translate(key, data) {
  return I18n.translate(key, data);
}

// _interpolate :: forall a. Fn2 String a String
export function _interpolate(message, data) {
  return I18n.interpolate(message, data);
}

// _translateIn :: forall a. Fn3 String String a String
export function _translateIn(locale, key, data) {
  var dataWithLocale = Object.create(data);
  dataWithLocale.locale = locale;
  return I18n.translate(key, dataWithLocale);
}

// _getGlobalLocale :: Effect String
export function _getGlobalLocale() {
  return I18n.locale;
}

// _setGlobalLocale :: EffectFn1 () String Unit
export function _setGlobalLocale(locale) {
  I18n.locale = locale;
}

// _getGlobalDefaultLocale :: Effect String
export function _getGlobalDefaultLocale() {
  return I18n.defaultLocale;
}
