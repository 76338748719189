import GroupToggle from "./components/GroupToggle";
import App from "./components/App";
import Store from "./services/Store";
import testLocalStorage from "./utils/testLocalStorage";
import initCartFabAttention from "./utils/initCartFabAttention";
import {} from "./utils/modal";
import { init as initRating } from "./utils/Rating";
import "vanilla-cookieconsent";
import getCookieConfig from "./utils/CookieConsentConfig";
import { initHrkDualPrices } from "./services/hrkDualPrices";

const client = {
  /**
   * Init App component with global store.
   */
  initApp: function () {
    const initialState = {};
    const store = Store.create(App.init(initialState), App.update);

    store.subscribe(() => {
      App.view($("body"), store.getState(), store.dispatch);
    });

    store.dispatch({ type: "INIT" });
  },

  // initEqualWidth: function () {
  //   setTimeout(function () {
  //     equalWidth()
  //   }, 250) // make sure it will run at the end
  // },

  /**
   * Execute immediately without waiting for DOM.
   */
  executeImmediately: function () {
    GroupToggle.utils.updateIdAttr();

    // replace with empty function, so we don't log errors after unload
    window.onbeforeunload = function () {
      window.onerror = function (message, url, line_number) {};
    };

    // TODO: Is this needed? @Damian
    // Add a no-touch class to body, so that we can disable :hover styles (elements with hover require a double tap on safari)
    if ("ontouchstart" in document) {
      $("body").removeClass("no-touch");
    }

    // TODO: is this still working? (With caching etc)
    $.ajaxSetup({
      headers: {
        "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
      },
    });

    initHrkDualPrices();
  },

  initCart: function () {
    if ($("[data-cart]").length > 0) {
      import(/* webpackChunkName: "Cart" */ "./components/CartWrapper").then(
        (mod) => mod["default"].init()
      );
    }
  },

  initCheckout: function () {
    if ($(".ps-checkout").length > 0) {
      import(
        /* webpackChunkName: "Checkout" */ "./components/CheckoutWrapper"
      ).then((mod) => mod["default"].init());
    }
  },

  initOrderInfo: function () {
    if ($(".ps-order-info").length > 0) {
      import(
        /* webpackChunkName: "OrderInfo" */ "./components/OrderInfoWrapper"
      ).then((mod) => mod["default"].init());
    }
  },

  initReservation: function () {
    if ($("#ps-reservation").length > 0) {
      import(
        /* webpackChunkName: "Reservation" */ "./components/ReservationWrapper"
      ).then((mod) => mod["default"].init());
    }
  },

  initConsents: function () {
    if ($("#ps-consents").length > 0) {
      import(
        /* webpackChunkName: "Consents" */ "./components/ConsentsWrapper"
      ).then((mod) => mod["default"].init());
    }
  },

  /**
   * Init cookie warning
   */
  initCookieConsent: function () {
    if ("RestaumaticMobileApp" in window) {
      // Android mobile apps don't require cookie acceptance, because it is done when installing the app
      // IOS apps don't import this file
      document.cookie =
        'cc_cookie={"level":["functional","analytics","marketing"],"revision":0,"data":null,"rfc_cookie":false};path=/;samesite=None;Secure';
    }
    const consent = window.initCookieConsent();
    consent.run(getCookieConfig());
  },

  initSelectRestaurant: () => {
    if ($(".js-select-restaurant").length > 0) {
      import(
        /* webpackChunkName: "SelectRestaurant" */ "../../output/Components.SelectRestaurant/index.js"
      ).then((mod) =>
        mod.init({
          element: $(".js-select-restaurant"),
          country: Skubacz.configuration.country,
          accountId: Skubacz.configuration.account_id,
        })
      );
    }
  },

  initDeliveryForm: () => {
    if ($(".js-delivery-module").length > 0) {
      import(
        /* webpackChunkName: "DeliveryForm" */ "../../output/Components.DeliveryForm/index.js"
      ).then((mod) =>
        mod.init({
          element: $(".js-delivery-module"),
          country: Skubacz.configuration.country,
          accountId: Skubacz.configuration.account_id,
        })
      );
    }
  },
};

client.executeImmediately();

window.Skubacz.Store = Store;

// Exports for tests.
// Should probably be moved to their own entry point.
window.Skubacz.loadTestExports = () =>
  import(
    /* webpackChunkName: "Creator" */ "../../output/Restaumatic.Site.Components.Creator/index.js"
  )
    .then((MenuV2Creator) => {
      window.Skubacz.MenuV2Creator = MenuV2Creator;
    })
    .then(() =>
      import(
        /* webpackChunkName: "Creator" */ "../../output/Restaumatic.Site.Components.ComboCreator/index.js"
      )
    )
    .then((ComboCreator) => {
      window.Skubacz.ComboCreator = ComboCreator;
    })
    .then(() =>
      import(
        /* webpackChunkName: "Creator" */ "../../output/Restaumatic.Site.Encoding/index.js"
      )
    )
    .then((Encoding) => {
      window.Skubacz.Encoding = Encoding;
    });

function init() {
  testLocalStorage();
  client.initCookieConsent();
  client.initApp();
  initRating();
  client.initCart();
  client.initCheckout();
  client.initOrderInfo();
  client.initReservation();
  client.initConsents();
  client.initSelectRestaurant();
  client.initDeliveryForm();
  initCartFabAttention();
}

$(document).ready(() => {
  if (window.__pageInitHook__) {
    window.__pageInitHook__().then(init);
  } else {
    init();
  }
});
