export default function (Component) {
  function _disp(dispatch) {
    return function (action) {
      return dispatch(action)();
    };
  }

  function convertResult(result) {
    return {
      value0: result[0],
      value1: function (dispatch, getState) {
        return function () {
          return result[1].unsafePerform(_disp(dispatch), getState);
        };
      },
    };
  }

  return {
    init: function (state) {
      var result = Component.init(state);
      return convertResult(result);
    },
    update: function (state) {
      return function (action) {
        var result = Component.update(state, action);
        return convertResult(result);
      };
    },
    view: function ($element) {
      return function (state) {
        return function (dispatch) {
          return function () {
            return Component.view($element, state, _disp(dispatch));
          };
        };
      };
    },
  };
}
