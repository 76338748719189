/* global exports */
"use strict";

export function getAttr(attr) {
  return function (ob) {
    return function () {
      return ob.attr(attr);
    };
  };
}

export function postImpl(errback) {
  return function (callback) {
    return function (url) {
      return function (data) {
        return function () {
          // Effect
          $.post(url, data, "json").then(
            function (results) {
              callback(results)();
            },
            function (err) {
              errback(err)();
            }
          );
        };
      };
    };
  };
}

export function parents(selector) {
  return function (ob) {
    return function () {
      return ob.parents(selector);
    };
  };
}

export function length(ob) {
  return ob.length;
}

// toArray :: JQuery -> Array Node
export function toArray(jq) {
  return jq.toArray();
}

export function trigger(eventType) {
  return function (eventParams) {
    return function ($element) {
      return function () {
        $element.trigger(eventType, eventParams);
      };
    };
  };
}

export function getDocument() {
  return $(document);
}

export function getData(attr) {
  return function (ob) {
    return function () {
      return ob.data(attr);
    };
  };
}

// setData :: String -> Foreign -> JQuery -> Effect Unit
export function setData(attr) {
  return function (value) {
    return function (ob) {
      return function () {
        ob.data(attr, value);
      };
    };
  };
}

export function addBack(ob) {
  return function () {
    return ob.addBack();
  };
}

export function filter(selector) {
  return function (ob) {
    return function () {
      return ob.filter(selector);
    };
  };
}

export function one(evt) {
  return function (act) {
    return function (ob) {
      return function () {
        ob.one(evt, function (e) {
          act(e)(jQuery(this))();
        });
      };
    };
  };
}

// logErrorOnElement :: String -> JQuery -> Effect Unit
export function logErrorOnElement(msg) {
  return function (jqObj) {
    return function () {
      console.error(msg, jqObj);
    };
  };
}

// wrapNode :: Node -> JQuery
export function wrapNode(node) {
  return jQuery(node);
}

// removeAttr :: String -> JQuery -> Effect Unit
export function removeAttr(attr) {
  return function (jqObj) {
    return function () {
      jqObj.removeAttr(attr);
    };
  };
}

export function empty(jqObj) {
  return function () {
    jqObj.empty();
  };
}

// selector :: JQuery -> String
export function selector(jqObj) {
  return jqObj.selector || "";
}

export function addTestLoadedMarker(markerName) {
  return function () {
    jQuery(
      '<div data-testid="' +
        markerName +
        '" style="position: absolute; top: 0; left: 0; width: 1px; height: 1px;">'
    ).appendTo(document.body);
  };
}

export function focus(jqObj) {
  return function () {
    jqObj.focus();
  };
}
