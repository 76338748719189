import { utcToZonedTime } from "date-fns-tz";
import { format, parse, addDays } from "date-fns";

const getDateTime = (timeZone) => {
  return utcToZonedTime(new Date().valueOf(), timeZone);
};

const parseDateTime = (dateTime, fmt) => {
  return parse(dateTime, fixFormat(fmt), 0);
};

const fixFormat = (fmt) => fmt.replace("YYYY", "yyyy").replace("DD", "dd");

const daysToSeconds = (days) => {
  return days * 86400;
};

const timeToSeconds = (timeStr) => {
  const time =
    timeStr instanceof Date ? timeStr : parseDateTime(timeStr, "HH:mm:ss");
  return time.getHours() * 3600 + time.getMinutes() * 60 + time.getSeconds();
};

const secondsToTime = (seconds) => {
  const d = new Date(1970, 1, 1);
  d.setSeconds(seconds);
  return d;
};

const shiftDays = (dateTime, shift) => {
  return addDays(dateTime, shift);
};

const timeToString = (time, fmt = "HH:mm:ss") => {
  return format(time, fixFormat(fmt));
};

const isDayBetween = (now, start, end) => {
  return (
    toNumericDate(start) <= toNumericDate(now) &&
    toNumericDate(now) <= toNumericDate(end)
  );
};

const toNumericDate = (date) => {
  return date.getFullYear() * 10000 + date.getMonth() * 100 + date.getDate();
};

// nd=0 ... sb=6
const dayIndex = (dateTime) => {
  return dateTime.getDay();
};

// pn=1 ... nd=7
const dayIndexIso = (dateTime) => {
  const d = dateTime.getDay();
  return d === 0 ? 7 : d;
};

export default {
  getDateTime,
  parseDateTime,
  daysToSeconds,
  timeToSeconds,
  secondsToTime,
  shiftDays,
  timeToString,
  isDayBetween,
  dayIndex,
  dayIndexIso,
};
