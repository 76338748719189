import onInit from "../utils/onInit";
import getTimestamp from "../utils/getTimestamp";
import Cmd from "../utils/Cmd";
import StorageService from "../services/StorageService";

const isSessionNotify = (key) => key.split("-")[1] === "session";
const isStorageSupported = isLocalStorage() && isSessionStorage();
const notifyElements = {
  notifySelector: ".js-notify",
  notifyOldBrowserSelector: "#notification-old-browser",
  notifyStorageSelector: ".js-notify-storage",
  notifyCloseClassName: "js-notify-close",
};

/**
 * Check for Internet Explorer.
 */
function isIE() {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE"); // IE 10 or below
  const trident = ua.indexOf("Trident/"); // IE 11

  return msie > 0 || trident > 0;
}

/**
 * Check for not supported browser.
 *
 * Note that this code won't execute in case of JS errors, so essential checks are in pendolino `_scripts.html.erb`.
 *
 * TODO: Implement feature detection.
 */
function isNotSupportedBrowser() {
  return isIE();
}

function getCurrentTimeInSeconds() {
  return new Date().getTime() / 1000;
}

/**
 * Initiate state of Notify component.
 *
 * @returns {function(*, *)}
 */
function initNotifyState() {
  const isNotificationOutOfDate = (expiration) =>
    expiration && getCurrentTimeInSeconds() > expiration;
  const isSessionVisible = (key) =>
    !isStorageSupported || !sessionStorage.getItem(key);
  const isRegularVisible = (key, expiration) =>
    (!StorageService.getItem(key) && !isNotificationOutOfDate(expiration)) ||
    Skubacz.configuration.editing;
  const isVisible = (key, expiration) => {
    const visibilityCheck = (key, expiration) =>
      isSessionNotify(key)
        ? isSessionVisible(key)
        : isRegularVisible(key, expiration);

    if (key === "notify-session-old-browser") {
      // special case for old browser notification
      return isNotSupportedBrowser() && visibilityCheck(key, expiration);
    } else {
      return visibilityCheck(key, expiration);
    }
  };

  return (dispatch, getState) => {
    if (isStorageSupported) {
      // TODO/Info: Notification about missing localStorage support probably won't be needed if whole project will use StorageService fallback.
      $(notifyElements.notifyStorageSelector).remove();
    }

    $(notifyElements.notifySelector).each((index, el) => {
      const $notification = $(el);
      const key = $notification.data("notify-key");
      const expiration = $notification.data("expiration");

      if (typeof key === "undefined") {
        throw new Error("data-notify-key attribute is required!");
      }

      dispatch({
        type: "ADD_NOTIFY",
        key: key,
        visible: isVisible(key, expiration),
      });
    });
  };
}

/**
 * Apply `toggle` side effects.
 *
 * @param state
 * @returns {function(*, *)}
 */
function applyToggleSideEffects(state) {
  return (dispatch, getState) => {
    for (let i = 0; i < state.length; i++) {
      const key = state[i].key;

      if (state[i].visible) {
        continue;
      }

      if (isSessionNotify(key)) {
        if (isStorageSupported) {
          sessionStorage.setItem(key, true);
        }
      } else {
        removeOldNotifications();
        StorageService.setItem(key, getTimestamp());
      }
    }
  };
}

/**
 * Check for `localStorage` support.
 *
 * @returns {boolean}
 */
function isLocalStorage() {
  const key = "localStorage";

  try {
    localStorage.setItem(key, key);
    localStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
}

/**
 * Check for `sessionStorage` support.
 *
 * @returns {boolean}
 */
function isSessionStorage() {
  const key = "sessionStorage";

  try {
    sessionStorage.setItem(key, key);
    sessionStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
}

/**
 * Remove old notifications.
 *
 * @param regex Regex to match against localStorage key.
 */
function removeOldNotifications(regex = "notification-") {
  if (isStorageSupported) {
    for (let k in localStorage) {
      if (localStorage.hasOwnProperty(k) && k.match(regex)) {
        StorageService.removeItem(localStorage)(k);
      }
    }
  }
}

export default {
  init: (state = []) => [state, Cmd.of(initNotifyState())],

  update: (state, action) => {
    switch (action.type) {
      case "ADD_NOTIFY": {
        const newState = state.concat({
          key: action.key,
          visible: action.visible,
        });

        return [newState, Cmd.none];
      }
      case "TOGGLE_NOTIFY": {
        const newState = state.map(function (s) {
          if (s.key === action.key) {
            return Object.assign(s, {
              visible: action.visible,
            });
          } else {
            return s;
          }
        });

        return [newState, Cmd.of(applyToggleSideEffects(newState))];
      }
      default:
        return [state, Cmd.none];
    }
  },

  view: ($element, state, dispatch) => {
    $element.each((index, el) => {
      const $notification = $(el);
      const $notificationClose = $notification.hasClass(
        notifyElements.notifyCloseClassName
      )
        ? $notification
        : $notification.find("." + notifyElements.notifyCloseClassName);
      const key = $notification.data("notify-key");
      const delay = $notification.data("delay")
        ? parseInt($notification.data("delay"))
        : 0;

      for (let i = 0; i < state.length; i++) {
        if (state[i].key === key) {
          if (state[i].visible) {
            setTimeout(function () {
              $notification.fadeIn();
            }, delay);
          } else {
            $notification.fadeOut();
          }
          break;
        }
      }

      onInit($notification, () => {
        $notificationClose.on("click", function (e) {
          dispatch({type: "TOGGLE_NOTIFY", key: key, visible: false});
          e.preventDefault();
        });
      });
    });
  },
};
