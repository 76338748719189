import { setHrkDualPricesEnabled } from "../../../output/Restaumatic.I18n.Currency";
export function initHrkDualPrices() {
    const now = new Date();
    // 2022-09-01 00:00:00 CEST = 2022-08-31 22:00:00Z
    const targetDate = new Date(Date.UTC(2022, 7, 31, 22, 0, 0));
    if (now >= targetDate) {
        console.log("Enabling HRK dual prices");
        setHrkDualPricesEnabled(true)();
    }
    else {
        console.log(`HRK dual prices disabled (now=${now.toISOString()} target=${targetDate.toISOString()})`);
    }
}
