import Cmd from "../utils/Cmd";
import keepDropdownOpen from "../utils/keepDropdownOpen";
import onInit from "../utils/onInit";

const $responsiveNav = $("#responsive-nav");
const defaultOptions = {
  activeClassName: "active",
  dropdownSelector: ".dropdown",
  dropdownToggleSelector: ".dropdown-toggle",
};

/**
 * Initiate state of Navigation.
 *
 * @param state
 * @returns {function(*, *)}
 */
function initNavigationState(state) {
  return (dispatch, getState) => {
    dispatch({ type: "ACTIVATE_ITEM", target: location.pathname });
  };
}

/**
 * Mark active navigation links.
 *
 * @param $navigation
 * @param state
 * @param options
 */
function markActiveNavLinks($navigation, state, options) {
  $navigation
    .find(`[href="${state.pathname}"]`)
    .addClass(options.activeClassName)
    .closest(options.dropdownSelector)
    .find(options.dropdownToggleSelector)
    .addClass(options.activeClassName);
}

/**
 * Get navigation position.
 *
 * @param {jQuery} $nav Navigation element.
 * @returns {string} Navigation position (left, right or fullwidth).
 */
function getNavigationMode($nav) {
  let offset = $nav.offset();
  let distanceLeft = Math.round(offset.left - $(document).scrollLeft());
  let distanceRight = Math.round(
    $(window).width() - distanceLeft - $nav.width()
  );

  if (distanceLeft <= 0 && distanceRight > 0) {
    return "left";
  } else if (distanceLeft > 0 && distanceRight <= 0) {
    return "right";
  }

  return "fullwidth";
}

/**
 * Close the navigation with swipe gestures within the navigation and its background.
 *
 * @param {jQuery} $nav Navigation element.
 * @param {function} dispatch
 */
function swipeToClose($nav, dispatch) {
  const $elements = $nav.add(".mobile-navigation-backdrop");
  let touchStartX = 0;
  let touchStartY = 0;
  let touchMoveEndX = 0;
  let touchMoveEndY = 0;
  let isClosing = false;

  $elements.each(function (i, el) {
    const $el = $(el);

    $el.on("touchstart", function (e) {
      isClosing = false;
      touchStartX = e.originalEvent.touches[0].clientX;
      touchStartY = e.originalEvent.touches[0].clientY;
    });

    $el.on("touchmove", function (e) {
      touchMoveEndX = e.originalEvent.touches[0].clientX;
      touchMoveEndY = e.originalEvent.touches[0].clientY;

      const threshold = 20; // prevent accidental closing with gentle gestures
      const xDiff = touchStartX - touchMoveEndX;
      const yDiff = touchStartY - touchMoveEndY;

      if (Math.abs(xDiff) > Math.abs(yDiff) && Math.abs(xDiff) >= threshold) {
        // horizontal swipe
        const navigationPosition = getNavigationMode($nav);

        if (xDiff > 0 && navigationPosition === "left") {
          // left swipe and left navigation
          if (!isClosing) {
            dispatch({ type: "CLOSE_NAV" });
          }

          isClosing = true;
        } else if (xDiff < 0 && navigationPosition === "right") {
          // right swipe and right nawigation
          if (!isClosing) {
            dispatch({ type: "CLOSE_NAV" });
          }

          isClosing = true;
        }
      }
    });
  });
}

export default {
  init: (state = {}) => [
    Object.assign(
      {},
      {
        open: false,
        pathname: "/",
      },
      state
    ),
    Cmd.of(initNavigationState(state)),
  ],

  update: (state, action) => {
    switch (action.type) {
      case "ACTIVATE_ITEM": {
        const newState = Object.assign({}, state, {
          pathname: action.target,
        });

        return [newState, Cmd.none];
      }

      case "TOGGLE_NAV": {
        const newState = Object.assign({}, state, {
          open: !state.open,
        });

        return [newState, Cmd.none];
      }

      case "CLOSE_NAV": {
        const newState = Object.assign({}, state, {
          open: false,
        });

        return [newState, Cmd.none];
      }

      default:
        return [state, Cmd.none];
    }
  },

  view: ($navigation, state, dispatch) => {
    const $navToggle = $(".js-navigation-toggle");

    if (state.open) {
      $navToggle.addClass("is-active");
      $responsiveNav.addClass("is-open").attr("aria-expanded", "true");
      $("body").addClass("l-navigation-open");
    } else {
      $navToggle.removeClass("is-active");
      $responsiveNav.removeClass("is-open").attr("aria-expanded", "false");
      $("body").removeClass("l-navigation-open");
    }

    onInit($navigation, () => {
      const options = Object.assign(
        {},
        defaultOptions,
        $navigation.data("navigation")
      );

      $navToggle.on("click", function (e) {
        dispatch({ type: "TOGGLE_NAV" });
        e.preventDefault();
      });

      $(document).on("to-element.skubacz.scroll", function (e) {
        dispatch({ type: "CLOSE_NAV" });
      });

      $navigation.each(function (index, el) {
        const $nav = $(el);

        markActiveNavLinks($nav, state, options);
        keepDropdownOpen($nav.find(options.dropdownSelector), options);

        if ($nav.hasClass("mobile-navigation")) {
          swipeToClose($nav, dispatch);
        }
      });
    });
  },
};
