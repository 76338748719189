// https://developers.google.com/analytics/devguides/collection/analyticsjs

const allowedExtraParams = [
  "dataSource",
  "referrer",
  "campaignName",
  "campaignSource",
  "campaignMedium",
  "campaignKeyword",
  "campaignContent",
  "campaignId",
  "appName",
  "appId",
  "appVersion",
  "appInstallerId",
];

function createTracker(key, measurementId, config, extraParams) {
  return {
    init: function (clientId) {
      var clientIdSettings = clientId ? { clientId: clientId } : {};
      console.log(
        `Initializing analytics (analytics.js v3) ${measurementId} with clientId ${clientId}`
      );
      ga(
        "create",
        measurementId,
        "auto",
        Object.assign(
          {},
          { name: key, cookieFlags: "SameSite=None; Secure"},
          clientIdSettings
        )
      );

      ga(`${key}.set`, "dimension1", config.themeName);
      ga(`${key}.set`, "dimension2", config.siteName);
      ga(`${key}.set`, "dimension3", window.location.origin);

      Object.entries(extraParams).forEach(function ([k, v]) {
        if (allowedExtraParams.indexOf(k) >= 0) {
          ga(`${key}.set`, k, v);
        } else {
          console.warn(
            "getExtraParams - key " +
              k +
              " is not allowed. Only: " +
              JSON.stringify(allowedExtraParams) +
              " can be set"
          );
        }
      });
    },
    pageView: function (viewName) {
      if (key == "restaumatic") {
        // The provided viewName is stripped from site-specific names, i.e. `restauracja/venezia` and `restauracja/peppers` are collapsed to `menu`, for easier analysis.
        // We set the page on the tracker to hopefully make it work for site performance
        ga(`${key}.set`, "page", "/" + viewName);
        ga(`${key}.send`, "pageview", viewName);
      } else {
        ga(`${key}.send`, "pageview");
      }
    },
    onError: function (message) {
      ga(`${key}.send`, "exception", { exDescription: message });
    },
    customEvent: function (item) {
      var v = [
        key + ".send",
        "event",
        item.category || config.siteSlug,
        item.action,
      ];
      if (item.optLabel) {
        v.push(item.optLabel);
      }
      if (item.optValue) {
        v.push(item.optValue);
      }
      ga.apply(null, v);
    },
    standardEvent: function (event) {
      const e = event["analytics_v3"];
      if (e && e.name == "Purchase") {
        var p = e.value;
        // Record eccomerce transaction
        ga(`${key}.require`, "ecommerce");
        ga(`${key}.ecommerce:addTransaction`, e.value.transaction);
        e.value.items.forEach(function (item) {
          ga(`${key}.ecommerce:addItem`, item);
        });
        ga(`${key}.ecommerce:send`);
      } else if (e) {
        // Basic handling of "named" events (just record a name)
        ga(`${key}.send`, "event", "NamedEvent", e.name);
      }
    },
  };
}

export default { createTracker };
