// type CurrencyOptions =
//   { format :: String
//   , precision :: Int
//   , separator :: String
//   , delimiter :: String
//   , unit :: String
//   }
//
// _formatCurrency :: Fn2 CurrencyOptions Number String
export function _formatCurrency(options, n) {
  return I18n.toCurrency(n, {
    format: options.format,
    precision: options.precision,
    separator: options.separator,
    delimiter: options.delimiter,
    unit: options.unit,
    significant: false,
    strip_insignificant_zeros: false,
  });
}
