/**
 * Show a Bootstrap modal.
 * Returns Promise which resolves when the modal is shown.
 */
export function _showModal(elem) {
  const modal = elem.data("bs.modal");
  if (modal && modal.isShown) {
    console.log("Modal already shown, ignoring showModal()");
    return Promise.resolve();
  }

  const finished = new Promise((resolve) =>
    elem.one("shown.bs.modal", () => resolve())
  );
  if (window.__addTask__) {
    const task = "showModal:" + elem.attr("id");
    window.__addTask__(task);
    finished.then(() => window.__doneTask__(task));
  }
  elem.modal("show");
  return finished;
}

/**
 * Show a Bootstrap modal.
 * Returns Promise which resolves when the modal is hidden.
 */
export function _hideModal(elem) {
  const modal = elem.data("bs.modal");
  if (!modal || !modal.isShown) {
    console.log("Modal already hidden, ignoring hideModal()");
    return Promise.resolve();
  }

  const finished = new Promise((resolve) =>
    elem.one("hidden.bs.modal", () => resolve())
  );
  if (window.__addTask__) {
    const task = "hideModal:" + elem.attr("id");
    window.__addTask__(task);
    finished.then(() => window.__doneTask__(task));
  }
  elem.modal("hide");
  return finished;
}

export function wrapNode(node) {
  return jQuery(node);
}
