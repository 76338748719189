import Tracking from "./Tracking";
import { I18n } from "./../services/I18n";
// Config for: https://github.com/orestbida/cookieconsent
export default function getCookieConfig() {
    console.log("Get cookie consent config");
    const settings_link = ` <button type="button" data-cc="c-settings" class="cc-link">${I18n.t("cookie_consent.consent_modal.settings")}</button>`;
    const privacy_policy_link = `<a href="/privacy-policy">${I18n.t("cookie_consent.consent_modal.privacy_policy")}</a>`;
    return {
        autorun: true,
        current_lang: 'en',
        page_scripts: true,
        cookie_same_site: "None",
        onAccept: function ({ level }) {
            const tracking = Tracking.init();
            const layer = window;
            layer.gtag("consent", "update", {
                'ad_storage': level.includes('marketing') ? 'granted' : 'denied',
                'analytics_storage': level.includes('analytics') ? 'granted' : 'denied'
            });
            // HACK To make GTM accept the new consents
            layer.dataLayer.push({ event: "gtm.init_consent" });
            Skubacz.tracking({ tag: "ConsentsAccepted", initialPageView: true, cookieLevels: level });
        },
        languages: {
            'en': {
                consent_modal: {
                    title: I18n.t("cookie_consent.consent_modal.title"),
                    description: I18n.t("cookie_consent.consent_modal.description", { settings_link, privacy_policy_link }),
                    primary_btn: {
                        text: I18n.t("cookie_consent.consent_modal.accept_all"),
                        role: 'accept_all'
                    },
                    secondary_btn: {
                        text: I18n.t("cookie_consent.consent_modal.accept_necessary"),
                        role: 'accept_necessary'
                    }
                },
                settings_modal: {
                    title: I18n.t("cookie_consent.settings_modal.title"),
                    save_settings_btn: I18n.t("cookie_consent.settings_modal.save_settings_btn"),
                    accept_all_btn: I18n.t("cookie_consent.settings_modal.accept_all_btn"),
                    blocks: [
                        {
                            title: I18n.t("cookie_consent.privacy.title"),
                            description: I18n.t("cookie_consent.privacy.description")
                        },
                        {
                            title: I18n.t("cookie_consent.functional_cookies.title"),
                            description: I18n.t("cookie_consent.functional_cookies.description"),
                            toggle: {
                                value: 'functional',
                                enabled: true,
                                readonly: true
                            },
                            cookie_table: [
                                {
                                    col1: 'cc_cookie',
                                    col2: 'restaumatic.com',
                                    col3: I18n.t("cookie_consent.cookie_table.consent")
                                },
                                {
                                    col1: 'currentRestaurant',
                                    col2: 'restaumatic.com',
                                    col3: I18n.t("cookie_consent.cookie_table.current_restaurant")
                                },
                                {
                                    col1: 'cart',
                                    col2: 'restaumatic.com',
                                    col3: I18n.t("cookie_consent.cookie_table.cart")
                                },
                                {
                                    col1: 'locationCache',
                                    col2: 'restaumatic.com',
                                    col3: I18n.t("cookie_consent.cookie_table.location_cache")
                                },
                                {
                                    col1: 'user',
                                    col2: 'restaumatic.com',
                                    col3: I18n.t("cookie_consent.cookie_table.user")
                                },
                                {
                                    col1: 'deliveryMethod',
                                    col2: 'restaumatic.com',
                                    col3: I18n.t("cookie_consent.cookie_table.delivery_method")
                                },
                                {
                                    col1: 'deliveryAddress',
                                    col2: 'restaumatic.com',
                                    col3: I18n.t("cookie_consent.cookie_table.delivery_address")
                                }
                            ]
                        },
                        {
                            title: I18n.t("cookie_consent.analytics_cookies.title"),
                            description: I18n.t("cookie_consent.analytics_cookies.description"),
                            toggle: {
                                value: 'analytics',
                                enabled: true,
                                readonly: false
                            },
                            cookie_table: [
                                {
                                    col1: '^_ga',
                                    col2: 'google.com',
                                    col3: I18n.t("cookie_consent.cookie_table.google_analytics"),
                                    is_regex: true
                                },
                                {
                                    col1: '^_gid',
                                    col2: 'google.com',
                                    col3: I18n.t("cookie_consent.cookie_table.google_analytics"),
                                    is_regex: true
                                },
                                {
                                    col1: '^_utm',
                                    col2: 'google.com',
                                    col3: I18n.t("cookie_consent.cookie_table.google_analytics"),
                                    is_regex: true
                                },
                                {
                                    col1: '^ahoy',
                                    col2: 'restaumatic.com',
                                    col3: I18n.t("cookie_consent.cookie_table.ahoy"),
                                    is_regex: true
                                }
                            ]
                        },
                        {
                            title: I18n.t("cookie_consent.marketing_cookies.title"),
                            description: I18n.t("cookie_consent.marketing_cookies.description"),
                            toggle: {
                                value: 'marketing',
                                enabled: true,
                                readonly: false
                            },
                            // Google cookie list: https://business.safety.google/adscookies/
                            cookie_table: [
                                {
                                    col1: '^_fbp',
                                    col2: 'facebook.com',
                                    col3: I18n.t("cookie_consent.cookie_table.facebook_pixel"),
                                    is_regex: true
                                },
                                {
                                    col1: '^_gcl',
                                    col2: 'google.com',
                                    col3: I18n.t("cookie_consent.cookie_table.google_ads"),
                                    is_regex: true
                                },
                                {
                                    col1: '^_gac',
                                    col2: 'google.com',
                                    col3: I18n.t("cookie_consent.cookie_table.google_ads"),
                                    is_regex: true
                                },
                                {
                                    col1: 'CONVERSION',
                                    col2: 'google.com',
                                    col3: I18n.t("cookie_consent.cookie_table.google_ads"),
                                    is_regex: true
                                },
                                {
                                    col1: '^_opt',
                                    col2: 'google.com',
                                    col3: I18n.t("cookie_consent.cookie_table.google_optimize"),
                                    is_regex: true
                                },
                            ]
                        }
                    ],
                    cookie_table_headers: [
                        { col1: I18n.t("cookie_consent.cookie_table.headers.name") },
                        { col2: I18n.t("cookie_consent.cookie_table.headers.source") },
                        { col3: I18n.t("cookie_consent.cookie_table.headers.description") },
                    ]
                }
            }
        }
    };
}
